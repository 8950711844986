import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../Logo/Logo';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from "react-device-detect";
import sideBarRoutes from '../../routes/routes';
import Logout from '../../view/Logout/Logout';

const Sidebar = (props) => {

    const closeSidebar = () => {
        if(isMobile){
            props.closeSidebar();
        }
    }

    const handleLogOutClick = () => {
        props.logoutClick(false);
    }
    
    return (
        <nav id="sidebar" className={props.isSidebarCollapsed ? "active" : ""}>
            <div className="sidebar-header">
                <Logo size="7"/>
                <h5>Labware S.p.A.</h5>
                <div className="clearfix">&nbsp;</div>
                <p>Benvenuto {props.username}!</p>
                <p>Codice rivenditore - {props.resellerCode}</p>
            </div>
            <div className="menu-section">RT Services</div>
            <ul className="nav navbar-nav" style={{textAlign: "left"}}>
                {sideBarRoutes.map((route, index) => {
                        return (
                            <li key={index}>
                                <NavLink to={`${'/home'+route.path}`} onClick={closeSidebar} ><FontAwesomeIcon icon={route.icon} style={{ marginRight: '5px' }} />{' '}{route.name}</NavLink>
                            </li>
                        )
                    })
                }
            </ul>
            <div style={{display:'flex', paddingTop: 30, alignItems: 'center', justifyContent: 'center'}}>
                <Logout changeUserLoggedState={handleLogOutClick} />
            </div>

			<div className='sidebar-footer'>
				Versione: {process.env.REACT_APP_VERSION}
			</div>
            
        </nav>
    );

}

export default Sidebar;