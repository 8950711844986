import React, { useEffect, useState } from 'react'
import Create from './Create';
import List from './List';
import { Button } from 'reactstrap';
import { faPlusSquare, faList, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isMobile } from "react-device-detect";
import useScreenOrientation from '../Common/Hook/ScreenOrientation';
import { useLocation } from 'react-router-dom';
import { linkDeviceToConfiguration } from '../../services/devices';
import GenericConfirmModal from './Modal/GenericConfirmModal';

const Configuration = () => {

    const [isCreateAction, setIsCreateAction ] = useState(false);
    const [autoconfigData, setAutoconfigData] = useState({configId: null, deviceId: null})
    const [genericConfirmOpen, setGenericConfirmOpen] = useState(false);

    const { orientation } = useScreenOrientation();
    const location = useLocation();

    useEffect(() => {
        const fromAutoConfiguration = location?.state?.from ?? null;
        if(fromAutoConfiguration && fromAutoConfiguration === "new_config"){
            setIsCreateAction(true);
        }

        if(location?.state?.configId || location?.state?.deviceId){
            setAutoconfigData({configId: location?.state?.configId, deviceId: location?.state?.deviceId})
        }
    },[location]);

    const linkConfiguration = async (device_matricola, idConfiguration) => {
        setAutoconfigData({configId: null, deviceId: null});
        if(device_matricola){
            const linkDeviceToConfResponse = await linkDeviceToConfiguration(device_matricola, idConfiguration);
            console.log(linkDeviceToConfResponse);
        }
    }

    const genericConfirmModalConfirm = () => {
        setGenericConfirmOpen(false);
        setIsCreateAction(false);
    }

    return (
        <div id="createBody" className="col-md-12">
            <div className="card text-left">
                <div className="card-header">
                    <FontAwesomeIcon icon={faFileAlt} /> {isCreateAction ? "Crea Modello" : "Lista Modelli"}  
                </div>
                <div className="card-body">
                    <React.Fragment>
                        {isCreateAction ?
                            <React.Fragment>
                                <Button outline color="primary" className="float-right mb-2" onClick={() => setGenericConfirmOpen(true)}>
                                    <FontAwesomeIcon icon={faList}></FontAwesomeIcon>
                                    {' '}Torna alla lista
                                </Button>
                                <div className="clearfix">&nbsp;</div>
                                <Create
                                    matricolaAutoConfig={location?.state?.deviceId ?? undefined} 
                                    extraActionToPerformOnSave={linkConfiguration}
                                    goToList={() => setIsCreateAction(false)}
									hasPlan={location?.state?.hasPlan ?? null}
                                />
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div style={{display: 'flex'}}>
                                    {isMobile && orientation.includes('portrait') ? <div style={{paddingTop: 10, paddingLeft: 10, color: 'red'}}> Per una corretta visualizzazione, ruotare il dispositivo  </div> : null}
                                    <div style={{marginLeft: 'auto'}}>
                                        <Button outline color="success" className="float-right" onClick={() => setIsCreateAction(true)}>
                                            <FontAwesomeIcon icon={faPlusSquare}></FontAwesomeIcon>
                                            {' '}Crea modello
                                        </Button>
                                    </div>
                                </div>

                                <List
                                    configurationIdAutoConfig={autoconfigData?.configId ?? null}
                                    deviceIdAutoConfig={autoconfigData?.deviceId ?? null}
									hasPlan={location?.state?.hasPlan ?? null}
                                />
                            </React.Fragment>
                        }
                    </React.Fragment>
                </div>
            </div>
            <GenericConfirmModal 
                isModalOpen={genericConfirmOpen}
                msg={'Perderai le modifiche non salvate, continuare?'}
                onConfirm={genericConfirmModalConfirm}
                onDismiss={() => setGenericConfirmOpen(false)}
            /> 
        </div>
    )
    
}


export default Configuration;