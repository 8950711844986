import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import Switch from 'react-switch';
import { Alert, Card, CardBody, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import ImageInput from '../../Common/ImageInput/ImageInput';
import FlagHint from './Items/FlagHint';
import flags from './data/flags';

export default class FlagsFormData extends Component {
    constructor(props){
        super(props);
		this.defaultSecondsDate = 60;
		//
        this.state = {
            fiscalHourFlag: 38,
            isfiscalHourInputShown: false,
            showDateAndHourSecondsInterval: 40,
            isShowDateAndHourSecondsIntervalInputShown: false,
            checked: false,
            flags : [],
            minute_value : '',
            hours_value : '',
            operational_parameters : 
            {
                cash_number : {  
                    id: 1,
                    description: 'Numero Cassa',
                    value : 1,
                    valid: true
                },
                print_intensity : {  
                    id: 2,
                    description: 'Intensità di stampa',
                    value : '',
                    valid: true
                },
                date_time_fiscal_closure : {  
                    id: 3,
                    description: 'Data Ora Chiusura Fiscale',
                    value : '',
                    hours_value: '',
                    hours_valid: true,
                    minutes_value: '',
                    minutes_valid: true,
                    valid: true
                },
                visualization_seconds_date : {  
                    id: 4,
                    description: 'Secondi per visualizzazione data / ora',
                    value : '',
                    valid: true
                },
                print_cutter_mode : {  
                    id: 6,
                    description: 'Modalità taglio carta',
                    value : '',
                    valid: true
                },
                password_web_interface : {  
                    id: 5,
                    description: 'Password Web Interface',
                    value : '22747',
                    valid: true
                },
                credit_button_mode : {
                    id: 7,
                    description: 'Modalità tasto CREDITO',
                    value : '0',
                    valid : true
                },
                bancomat_button_mode : {
                    id: 8,
                    description: 'Modalità tasto BANCOMAT',
                    value : '0',
                    valid : true
                },
				electronic_payment_offline: {
					id: 9,
					description: 'Modalità pagamento Elettronico Offline',
					value : '',
					valid : true
				},
				payment_channels: [
					{
						id: 10,
						description: "Canale Pagamento 1",
						value: "",
						valid: true
					},
					{
						id: 11,
						description: "Canale Pagamento 2",
						value: "",
						valid: true
					},
					{
						id: 12,
						description: "Canale Pagamento 3",
						value: "",
						valid: true
					},
				]
            },
            images : [
                { id: 3, height: "", width: "", content: "", ext: "",rawData: ""},
                { id: 4, height: "", width: "", content: "", ext: "",rawData: ""},
                { id: 5, height: "", width: "", content: "", ext: "",rawData: ""},
            ],

			savedSeconds: this.props.operational_data?.visualization_seconds_date.value,
        }

    }

    componentDidMount() {
        this.getFlagData();
        this.getOperationalData();
        const device_type = this.props.deviceType;

		this.setState({
			device_type: device_type,
		});

        // smaRT
        if(device_type === "2"){
            var operational_parameters = this.state.operational_parameters;
    
            operational_parameters.credit_button_mode.value = '0';
            operational_parameters.credit_button_mode.valid = true;

            operational_parameters.bancomat_button_mode.value = '0';
            operational_parameters.bancomat_button_mode.valid = true;
    
            this.setState({
                operational_parameters : operational_parameters
            })
        }

    }

    componentDidUpdate(prevProps) {
        if(prevProps.operational_data === undefined && this.props.operational_data !== undefined){
            this.getOperationalData();
        }

        if(prevProps.flag_data === undefined && this.props.flag_data !== undefined){
            this.getFlagData();
        }

        if(prevProps.deviceType !== this.props.deviceType){
            this.setState({
                device_type: this.props.deviceType,
            });
        }

    }

    getFlagData = () => {

        const currentFlags = [...flags];
        this.setState({
            flags: currentFlags
        }, () => {
            if(typeof this.props.flag_data == 'undefined') {
                this.saveData();
                return;
            }

            this.props.flag_data.settings.forEach( (flag) => {
				if(this.props.deviceType === "2") {
					switch (flag.id) {
						case 15:
							// only for smaRT force Display Smart true (id: 15)
							flag.value = true;
							break;
						case this.state.showDateAndHourSecondsInterval:
							if(!flag.edited) {
								// If not previously saved flag 40 then i override the default functions
								// only for smaRT force Visualizzazione automatica data/ora e Immagini true (id: 40)
								if(this.props.flag_data.images.filter( img => img.content !== "" ).length > 0) {
									flag.value = true;
									this.setState({isShowDateAndHourSecondsIntervalInputShown: true});
								}
								else {
									flag.value = false;
									this.setState({isShowDateAndHourSecondsIntervalInputShown: false});
								}
							}
							break;
						default:
							
							break;
					}
				}
                this.handleChange(
                    flag.id,
                    flag.value ? true : false,
					flag.edited
                );
            });

        });
		if(this.props.flag_data?.images && this.props.flag_data?.images.length > 0) {
			this.setState({
				images: [...this.props.flag_data?.images]
			});
		}
    }

    getOperationalData = () => {

        if(typeof this.props.operational_data == 'undefined') return;

        this.formatOperationalData();

    }

    formatOperationalData = () => {

        this.handleCashNumberInput(
            this.props.operational_data.cash_number.value
        );
        
        this.handlePrintIntensityChange(
            this.props.operational_data.print_intensity.value
        );
        
        this.handlePrintCutterModeChange(
            this.props.operational_data.print_cutter_mode.value
        );

        this.handleWebPasswordChange(
            this.props.operational_data.password_web_interface.value
        );

		if(this.props.operational_data.payment_channels) {
			this.props.operational_data.payment_channels[0] && this.handlePaymentChannelChange(this.props.operational_data.payment_channels[0].value, 0);
			this.props.operational_data.payment_channels[1] && this.handlePaymentChannelChange(this.props.operational_data.payment_channels[1].value, 1);
			this.props.operational_data.payment_channels[2] && this.handlePaymentChannelChange(this.props.operational_data.payment_channels[2].value, 2);
		}

        this.props.operational_data.credit_button_mode && this.handleCreditButtonModeChange( this.props.operational_data.credit_button_mode.value );

        this.props.operational_data.bancomat_button_mode && this.handleBancomatButtonModeChange( this.props.operational_data.bancomat_button_mode.value );

		this.props.operational_data.electronic_payment_offline && this.handleBancomatButtonModeChange( this.props.operational_data.electronic_payment_offline.value );

        let fiscalClosureTime = this.props.operational_data.date_time_fiscal_closure.value;
   
        if(fiscalClosureTime !== 0){
            
            this.handleChangeHour(fiscalClosureTime.substr(0,2));
   
            this.handleChangeMinute(fiscalClosureTime.substr(3,2));

        }
		let visualizationSecondsDate = this.props.operational_data.visualization_seconds_date.value;
        if(visualizationSecondsDate && visualizationSecondsDate !== 0){
            this.handleChangeVisualizationSeconds(visualizationSecondsDate);
        }
    }

    handleChange = (id,checked, isEditedByUser = false) => {

        let flag_array = this.state.flags;
        flag_array.filter( flag => {
            if(flag.id === id) {
                flag.value = checked;
				flag.edited = isEditedByUser;
            }
        })

        this.handleFiscalHourInputField(id, checked);
        this.handleShowDateAndHourSecondsInterval(id, checked);

        this.setState({
            flags: flag_array
        })

    }

    /**
     * shows/hides fiscal hour input when related flag is toggled
     */
    handleFiscalHourInputField = (flagId, isChecked) => {
        if(flagId === this.state.fiscalHourFlag){
            
            var operational_parameters = this.state.operational_parameters;

            if(!isChecked){
                operational_parameters.date_time_fiscal_closure.value = "";
                operational_parameters.date_time_fiscal_closure.minutes_value = "";
                operational_parameters.date_time_fiscal_closure.hours_value = "";
                operational_parameters.date_time_fiscal_closure.valid = true;
                operational_parameters.date_time_fiscal_closure.minutes_valid = true;
                operational_parameters.date_time_fiscal_closure.hours_valid = true;
            }
            else {
                if(operational_parameters.date_time_fiscal_closure.value !== ""){
                    operational_parameters.date_time_fiscal_closure.valid = 
                    operational_parameters.date_time_fiscal_closure.hours_valid = 
                    operational_parameters.date_time_fiscal_closure.minutes_valid = true;
                }
                else {
                    operational_parameters.date_time_fiscal_closure.valid = 
                    operational_parameters.date_time_fiscal_closure.hours_valid = 
                    operational_parameters.date_time_fiscal_closure.minutes_valid = false;
                }
                
            }

            this.setState({
                isfiscalHourInputShown: isChecked,
                operational_parameters: operational_parameters
            }, () => {
                this.checkValidForm();
            })
        }
    }

    /**
     * shows/hides date and hour seconds interval when related flag is toggled
     */
    handleShowDateAndHourSecondsInterval = (flagId, isChecked) => {
        if(flagId === this.state.showDateAndHourSecondsInterval){

            var operational_parameters = this.state.operational_parameters;

            if(!isChecked){
                operational_parameters.visualization_seconds_date.value = "";
                operational_parameters.visualization_seconds_date.valid = true;
            }
            else {
                if(operational_parameters.visualization_seconds_date.value !== ""){
                    operational_parameters.visualization_seconds_date.valid = true;
                }
                else {
					// If empty value, then i fill input with saved seconds (if someone mistakenly switch off and then switch on), or default 60 seconds if it was empty since the beginning
					operational_parameters.visualization_seconds_date.value = this.state.savedSeconds && this.state.savedSeconds !== "" ? this.state.savedSeconds : this.defaultSecondsDate
                    operational_parameters.visualization_seconds_date.valid = true;
                }
                
            }

            this.setState({
                isShowDateAndHourSecondsIntervalInputShown: isChecked,
                operational_parameters: operational_parameters
            }, () => {
                this.checkValidForm();
            })
        }
    }

    checkValidForm = () => {
        let valid = true;
        let operationalParametersStatus = this.state.operational_parameters;
        let flagStatus = this.state.flags;

        for(var key in operationalParametersStatus){
			if(operationalParametersStatus[key] instanceof Array === false) {
				if(operationalParametersStatus[key].valid === false){
					valid = false;
					break;
				}
			}
			else {
				valid = operationalParametersStatus[key].every(item => item.valid === true);
			}
        }
        
        if(valid){ //if valid is already false, skips those controls
            for(var flagKey in flagStatus){
                if(flagStatus[flagKey].valid === false){
                    valid = false;
                    break;
                }
            }
        }

        this.validateAndSave( valid );
        
    }

    validateAndSave = ( valid ) => {

        this.props.isStepValidated(valid, this.props.tabId);
        
        valid && this.saveData();
        
    }

    handleCashNumberInput = (input_value) => {

        var valid = false;
        var operational_parameters = this.state.operational_parameters;

        if(!isNaN(input_value) && input_value > 0 && input_value < 100){
            valid = true;    
        }
        
        operational_parameters.cash_number.value = input_value;
        operational_parameters.cash_number.valid = valid;

        this.setState({
            operational_parameters : operational_parameters
        }, () => this.checkValidForm());

    }

    handlePrintIntensityChange = (input_value) => {
        
        var operational_parameters = this.state.operational_parameters;

        operational_parameters.print_intensity.value = input_value;
        operational_parameters.print_intensity.valid = true;

        this.setState({
            operational_parameters : operational_parameters
        }, () => this.checkValidForm())

    }

    handlePrintCutterModeChange = (input_value) => {

        var operational_parameters = this.state.operational_parameters;

        operational_parameters.print_cutter_mode.value = input_value;
        operational_parameters.print_cutter_mode.valid = true;

        this.setState({
            operational_parameters : operational_parameters
        }, () => this.checkValidForm())

    }

    handleWebPasswordChange = (password) => {

        var valid = false;

        if(typeof password != "undefined" && password.length > 4 && password.length < 45){

            valid = true;

        }

        //TODO: Validate password
        var operational_parameters = this.state.operational_parameters;

        operational_parameters.password_web_interface.value = password;
        operational_parameters.password_web_interface.valid = valid;

        this.setState({
            operational_parameters : operational_parameters
        }, () => this.checkValidForm())

    }

    handleCreditButtonModeChange = (input_value) => {

        var operational_parameters = this.state.operational_parameters;

        operational_parameters.credit_button_mode.value = input_value;
        operational_parameters.credit_button_mode.valid = true;

        this.setState({
            operational_parameters : operational_parameters
        }, () => this.checkValidForm())

    }

	handleElectronicPaymentOfflineChange = (input_value) => {
		var operational_parameters = this.state.operational_parameters;

		operational_parameters.electronic_payment_offline.value = input_value;
		operational_parameters.electronic_payment_offline.valid = true;

		this.setState({
			operational_parameters : operational_parameters
		}, () => this.checkValidForm())
	}

	handlePaymentChannelChange = (input_value, index) => {
		var operational_parameters = this.state.operational_parameters;

		operational_parameters.payment_channels[index].value = input_value;
		operational_parameters.payment_channels[index].valid = true;
		

		// // Validation
		var previous = index - 1;
		while(previous >= 0) { 
			if(operational_parameters.payment_channels[previous].value === "") {
				operational_parameters.payment_channels[previous].valid = input_value !== "" ? false : true;
				// break;
			}
			previous--;
		}

		var next = index + 1;
		while(next < operational_parameters.payment_channels.length) {
			if(operational_parameters.payment_channels[next].value !== "") {
				operational_parameters.payment_channels[next].valid = input_value === "" ? false : true;;
				// break;
			}
			next++;
		}
		this.setState({
			operational_parameters : operational_parameters
		}, () => this.checkValidForm())
	}

    handleBancomatButtonModeChange = (input_value) => {

        var operational_parameters = this.state.operational_parameters;

        operational_parameters.bancomat_button_mode.value = input_value;
        operational_parameters.bancomat_button_mode.valid = true;

        this.setState({
            operational_parameters : operational_parameters
        }, () => this.checkValidForm())

    }

    handleChangeHour = (hour) => {
        var operational_parameters = this.state.operational_parameters;
        
        let minutes_value = operational_parameters.date_time_fiscal_closure.minutes_value;

        operational_parameters.date_time_fiscal_closure.value           = `${hour}:${minutes_value}`;
        operational_parameters.date_time_fiscal_closure.hours_value     = hour;
        operational_parameters.date_time_fiscal_closure.hours_valid     = hour.length > 0 ? true : false;
        operational_parameters.date_time_fiscal_closure.minutes_valid   = minutes_value.length > 0 ? true : false;
        operational_parameters.date_time_fiscal_closure.valid           = operational_parameters.date_time_fiscal_closure.hours_valid && operational_parameters.date_time_fiscal_closure.minutes_valid ;

        this.setState({
            operational_parameters : operational_parameters
        }, () => {
            this.checkValidForm()
        })
    }

    handleChangeMinute = (minutes) => {
        
        var operational_parameters = this.state.operational_parameters;
        let hours_value = operational_parameters.date_time_fiscal_closure.hours_value;
        
        operational_parameters.date_time_fiscal_closure.value           = `${hours_value}:${minutes}`;
        operational_parameters.date_time_fiscal_closure.minutes_value   = minutes;
        operational_parameters.date_time_fiscal_closure.minutes_valid   = minutes.length > 0 ? true : false;
        operational_parameters.date_time_fiscal_closure.hours_value     = hours_value;
        operational_parameters.date_time_fiscal_closure.hours_valid     = hours_value.length > 0 ? true : false;
        operational_parameters.date_time_fiscal_closure.valid = operational_parameters.date_time_fiscal_closure.hours_valid && operational_parameters.date_time_fiscal_closure.minutes_valid ;
        
        this.setState({
            operational_parameters : operational_parameters
        }, () => {
            this.checkValidForm()
        })

    }

    handleChangeVisualizationSeconds = (seconds) => {

        var operationalParameters = this.state.operational_parameters;

        operationalParameters.visualization_seconds_date.value = seconds;
        operationalParameters.visualization_seconds_date.valid = seconds.length > 0 ? true : false;
        
        this.setState({
            operational_parameters : operationalParameters
        }, () => {
            this.checkValidForm()
        })

    }

    setImage = (imageObject) => {
        const images = this.state.images;

		images.find((value, index) => {
			if(value.id === imageObject.id) {
				images[index].height     = imageObject.height;
				images[index].width      = imageObject.width;
				images[index].content    = imageObject.content;
				images[index].ext        = imageObject.ext;
				images[index].rawData    = imageObject.rawData;
			}
		})

		if(this.props.deviceType === "2") {

			this.state.flags.forEach(fl => {
				if(fl.id === this.state.showDateAndHourSecondsInterval && !fl.edited) {
					if(images.filter(img => img.content !== "").length > 0) {
						fl.value = true;
						this.handleShowDateAndHourSecondsInterval(this.state.showDateAndHourSecondsInterval, true)
					}
					else {
						fl.value = false;
						this.handleShowDateAndHourSecondsInterval(this.state.showDateAndHourSecondsInterval, false)
					}
				}

			});
			this.setState({flags:  this.state.flags});
		}



        this.setState({
            images : images
        }, () => {
            this.checkValidForm();
        });
    }


    saveData = () => {
        this.props.saveData(this.state.flags, this.state.operational_parameters, this.state.images);
    }

    /**
     * render methods
     */

    renderHours = () => {

        let hours = [
            <option key={-1} value={""}></option>
        ];

        for(let i = 0; i < 24; i++){

            let label = '' + i;
            let hour = <option key={i} value={i}>{label.padStart(2,"0")}</option>

            hours.push(hour);

        }

        return hours;

    }

    renderMinutes = () => {

        let minutes = [<option key={-1} value={""}></option>];

        for(let i = 0; i < 60; i++){
            let label = '' + i;
            let minute = <option key={i} value={i}>{label.padStart(2,"0")}</option>

            minutes.push(minute);

        }

        return minutes;
    }

    renderFlags = ({id, description, value, disabled, hint}) => {

        if(id === 15 && this.state.device_type == "2") return;
		const isEditedByUser = true;

        return <React.Fragment key={id}>
            <hr/>
            <Row>
                {isMobile ? 
                    <Col md={6} style={{ display: 'flex', alignItems: 'center', padding: 5}}>
                        <Label style={{ margin: 0, paddingRight: 20 }}> [ Flag {id} ] {description} </Label>
                        <Switch key={id} onChange={ e => this.handleChange(id, e, isEditedByUser)} checked={value}  disabled={!this.props.isEnabledForEdits || disabled}/>
                    </Col>
                :( <>
                        <Col md={5}>
                            <Label> [ Flag {id} ] {description} </Label>
                        </Col>
                        <Col md={1}>
                            <Switch key={id} onChange={ e => this.handleChange(id, e, isEditedByUser)} checked={value}  disabled={!this.props.isEnabledForEdits || disabled}/>
                        </Col>
                    </>)
                }
                <Col md={6}>

                    <FlagHint hint={hint ? hint.IT : ''} />

                </Col>
            </Row>
            <Row>
                <Col>
                    {/* if flag is flag 38, will be shown this input */}
                    <div className="form-inline" hidden={id === this.state.showDateAndHourSecondsInterval && this.state.isShowDateAndHourSecondsIntervalInputShown ? false : true }>
                        <Input 
                            invalid={this.state.operational_parameters.visualization_seconds_date.valid ? false : true}
                            type="number"
                            value={this.state.operational_parameters.visualization_seconds_date.value}
                            onChange = { e => this.handleChangeVisualizationSeconds(e.target.value)}
							min="0"
                        /> 
                    </div>
                    { /* Flag 39 input */ }
                    <div className="form-inline" hidden={id === this.state.fiscalHourFlag && this.state.isfiscalHourInputShown ? false : true }>
                        <Input 
                            value={ this.state.operational_parameters.date_time_fiscal_closure.hours_value }
                            invalid={ this.state.operational_parameters.date_time_fiscal_closure.hours_valid ? false : true }
                            type="select" 
                            onChange = { e => this.handleChangeHour(e.target.value)}>
                            { this.renderHours() }
                        </Input>
                        <span>&nbsp;:&nbsp;</span>
                        <Input 
                            type="select" 
                            value={ this.state.operational_parameters.date_time_fiscal_closure.minutes_value }
                            invalid={ this.state.operational_parameters.date_time_fiscal_closure.minutes_valid ? false : true }
                            onChange = { e => this.handleChangeMinute(e.target.value) }>
                            { this.renderMinutes() }
                        </Input>
                    </div>
                </Col>
            </Row>
        </React.Fragment>

    }
    
        

    render() {
        return (
            <React.Fragment>
                <br />
                <h5>Impostazioni di Sistema</h5>

                <Row>

                    { /* Numero Cassa */}
                    <Col md={6}>
                        <div className={isMobile ? 'flex-row' : ''}>
                            <Label> Numero Cassa </Label>
                            <Input 
                                type="number" 
                                value={this.state.operational_parameters.cash_number.value} 
                                onChange = {e => this.handleCashNumberInput(e.target.value)}
                                invalid = {!this.state.operational_parameters.cash_number.valid}
                            />
                        </div>
                    </Col>
                    
                    { /* Intensità Stampa */}
                    <Col md={6}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label> Intensità Stampa </Label>
                                <Input
                                    value={this.state.operational_parameters.print_intensity.value} 
                                    type="select" 
                                    name="select" 
                                    id="printIntensity" 
                                    onChange={e => this.handlePrintIntensityChange(e.target.value)}
                                    invalid = {!this.state.operational_parameters.print_intensity.valid}
                                >
                                    <option value="0">Normale</option>
                                    <option value="1">Media</option>
                                    <option value="2">Massima</option>
                                </Input>
                            </div>
                        </FormGroup>
                    </Col>
                    
                </Row>
                <Row>

                    { /* Taglio Carta solo per Basiq */}
					{this.state.device_type == "1" && 
						<Col md={6}>
							<FormGroup>
                                <div className={isMobile ? 'flex-row' : ''}>
                                    <Label> Taglio Carta </Label>
                                    <Input
                                        value={this.state.operational_parameters.print_cutter_mode.value}  
                                        type="select" 
                                        name="select" 
                                        id="printCutterMode" 
                                        onChange={e => this.handlePrintCutterModeChange(e.target.value)}
                                        invalid = {!this.state.operational_parameters.print_cutter_mode.valid}
                                    >
                                        <option value="0">Parziale</option>
                                        <option value="1">Totale</option>
                                        <option value="2">No</option>
                                    </Input>
                                </div>
								</FormGroup>
						</Col>
					}

                    { /* WEB Password */}
                    <Col md={6}>
                        <div className={isMobile ? 'flex-row' : ''}>
                            <Label> Web Password</Label>
                            <Input 
                                type="password" 
                                value={this.state.operational_parameters.password_web_interface.value}
                                onChange = {e => this.handleWebPasswordChange(e.target.value)}
                                invalid = {!this.state.operational_parameters.password_web_interface.valid}
                            />
                        </div>

                    </Col>
					{
					this.state.device_type == "2" && 
					<>
						<Col md={6}>
							<FormGroup>
                                <div className={isMobile ? 'flex-row' : ''}>
                                    <Label>Modalità pagamento Elettronico Offline</Label>
                                    <Input
                                        value={this.state.operational_parameters.electronic_payment_offline.value}
                                        type="select"
                                        name="electronicPaymentOffline"
                                        id="idElectronicPaymentOffline"
                                        onChange={ e => this.handleElectronicPaymentOfflineChange(e.target.value)}
                                        >
                                            <option default value="0">STAN/Canale pre-configurato</option>
                                            <option value="1">STAN/Canale Dinamico</option>
                                    </Input>
                                </div>
							</FormGroup>
						</Col>
					</>
					}
                </Row>
                {this.state.device_type == "1" && <Row>
                    <Col md={6}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label> Modalità tasto CREDITO</Label>
                                <Input
                                    value={this.state.operational_parameters.credit_button_mode.value}
                                    type="select"
                                    name="creditButtonMode"
                                    id="idCreditButtonMode"
                                    onChange={ e => this.handleCreditButtonModeChange( e.target.value)}
                                    >
                                        <option default value="0">Chiedi Tipo</option>
                                        <option value="1">Non Riscosso Beni</option>
                                        <option value="2">Non Riscosso Servizi</option>
                                        <option value="3">Non Riscosso, segue fattura</option>
                                        <option value="4">Non Riscosso DCRaSSN</option>
                                </Input>
                            </div>
                        </FormGroup>
                    </Col> 
                    <Col md={6}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label> Modalità tasto BANCOMAT</Label>
                                <Input
                                    value={this.state.operational_parameters.bancomat_button_mode.value}
                                    type="select"
                                    name="bancomatButtonMode"
                                    id="idBancomatButtonMode"
                                    onChange={ e => this.handleBancomatButtonModeChange( e.target.value)}
                                    >
                                        <option default value="0">Chiedi Tipo</option>
                                        <option value="1">BANCOMAT</option>
                                        <option value="2">Sconto a pagare</option>
                                        <option value="3">Buono multiuso</option>
                                </Input>
                            </div>
                        </FormGroup>
                    </Col>

                </Row>}

                {this.state.flags.map(this.renderFlags,this)}

				{ 
					this.state.device_type == "2" && 
					<>
						<br />
						<h5>Canali Pagamento</h5>
						<Row>
							<Col md={4} sm={12} xs={12}>
                                <div className={isMobile ? 'flex-row' : ''}>
                                    <Label>Canale Pagamento 1</Label>
                                    <Input 
                                        type="text" 
                                        value={this.state.operational_parameters.payment_channels[0].value}
                                        onChange = {e => this.handlePaymentChannelChange(e.target.value, 0)}
                                        invalid = {!this.state.operational_parameters.payment_channels[0].valid}
                                        maxLength={30}
                                    />
                                </div>
							</Col>
							<Col md={4} sm={12} xs={12}>
                                <div className={isMobile ? 'flex-row' : ''}>
                                    <Label>Canale Pagamento 2</Label>
                                    <Input 
                                        type="text" 
                                        value={this.state.operational_parameters.payment_channels[1].value}
                                        onChange = {e => this.handlePaymentChannelChange(e.target.value, 1)}
                                        invalid = {!this.state.operational_parameters.payment_channels[1].valid}
                                        maxLength={30}
                                    />
                                </div>
							</Col>
							<Col md={4} sm={12} xs={12}>
                                <div className={isMobile ? 'flex-row' : ''}>
                                    <Label>Canale Pagamento 3</Label>
                                    <Input 
                                        type="text" 
                                        value={this.state.operational_parameters.payment_channels[2].value}
                                        onChange = {e => this.handlePaymentChannelChange(e.target.value, 2)}
                                        invalid = {!this.state.operational_parameters.payment_channels[2].valid}
                                        maxLength={30}
                                    />
                                </div>
							</Col>
						</Row>
					</>
				}


                { this.state.device_type == "2" ? 
                    <>
                        <Alert className="mt-3" color="primary">Il formato consigliato per le immagini da caricare è di 320 x 240 pixel</Alert>
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
										<ImageInput loadedImage={this.state.images[0]} setImageForParent={this.setImage} id={3} full title="Immagine slide show 1" resize={{width: 320, height: 240}} />
										<ImageInput loadedImage={this.state.images[1]} setImageForParent={this.setImage} id={4} full title="Immagine slide show 2" resize={{width: 320, height: 240}} />
										<ImageInput loadedImage={this.state.images[2]} setImageForParent={this.setImage} id={5} full title="Immagine slide show 3" resize={{width: 320, height: 240}} />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                : null}

                <Row />

            </React.Fragment>
        )
    }
}
