import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import localStorageHelper from '../../helpers/localStorage.js';
import Navbar from '../../layout/Navbar/Navbar.jsx';
import Sidebar from '../../layout/Sidebar/Sidebar.jsx';
import * as ResellerAPI from '../../services/reseller.js';

import './Home.css';

const Home = function (){

    const [isSidebarCollapsed, setIsSidebarCollapsed ] = useState(false);
    const [isUserLogged, setIsUserLogged ] = useState(localStorageHelper.getAccessToken() ?? false);
    const [username, setUsername ] = useState('');
    const [currentMatricola, setCurrentMatricola ] = useState('');
    const [resellerDetails, setResellerDetails] = useState(null);

    const navigate = useNavigate();
    const location = useLocation();


    useEffect(() => {
        if(isUserLogged){
            setUsername(localStorageHelper.getUsername());
            const matricola = location.state?.serial ?? '';
            setCurrentMatricola(matricola);
        }else{
            navigate('/login');
        }
        getResellerDetails();
    },[]);

    const getResellerDetails = async () => {

        const details = await ResellerAPI.get();
        if(details.data && details.data.length > 0){
            setResellerDetails(details.data[0])
        }
    }

    const toggleSidebar = () => {
        setIsSidebarCollapsed(!isSidebarCollapsed);
    }

    const changeUserLoggedState = (isUserLogged) => {
        setIsUserLogged(isUserLogged);
        setUsername(localStorageHelper.getUsername());
    }

    return(
        <div className="wrapper">
            <Sidebar  
                isSidebarCollapsed={isSidebarCollapsed}
                username={username}
                resellerCode={resellerDetails?.resellerCode ?? ''} 
                closeSidebar={toggleSidebar} 
                logoutClick={changeUserLoggedState}
                />
            <div id="content">
                <Navbar toggleSidebar={toggleSidebar} />
                <Outlet context={{currentMatricola, setCurrentMatricola}}/>
                {/* <Content /> */}
            </div>
        </div>
    )
}

export default Home;