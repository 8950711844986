import React, { Component } from 'react';
import { isMobile } from "react-device-detect";
import { Col, FormFeedback, FormGroup, Input, Label, Row } from 'reactstrap';
import checkFiscalCode from '../../../validators/fiscalCodeValidator';
import checkVatCode from '../../../validators/vatCodeValidator';

export default class CustomerFormData extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            activity_name: '',
            activity_name_feedback: "",
            business_name: "",
            business_name_feedback: "",
            vat_code: "",
            vat_code_feedback: "",
            fiscal_code: "",
            fiscal_code_feedback: "",
            address: "",
            address_feedback: "",
            zip_code: "",
            zip_code_feedback: "",
            validation_status: {
                "activity_name" : false,
                "business_name" : false,
                "vat_code"      : false,
                "fiscal_code"   : true,
                "address"       : false,
                "zip_code"      : false
            }
        }
    }

    componentDidMount() {

        if(typeof this.props.data == 'undefined') return;
    
        this.setMountData();
        
    }

    componentDidUpdate(prevProps){
        
        if(prevProps.data === undefined && this.props.mod === 'edit' ){

            this.setMountData();

        }

    }

    setMountData = () => {

        let data = this.props.data;

        this.setState({
            activity_name : data.activity_name,
            business_name : data.business_name,
            vat_code : data.vat_code,
            fiscal_code : typeof data.fiscal_code == 'undefined' ? '' : data.fiscal_code,
            address : data.address,
            zip_code : data.zip_code,
        }, () => {
            this.validateActivityName(this.state.activity_name);
            this.validateBusinessName(this.state.business_name);
            this.validateVatCode(this.state.vat_code);
            this.validateFiscalCode(this.state.fiscal_code);
            this.validateAddress(this.state.address);
            this.validateZipCode(this.state.zip_code);
        });

    }

    validateActivityName = ( input ) => {

        const valueLength = input.length;
        const validationStatus = this.state.validation_status;

        var activityNameFeedback = "";

        if(valueLength === 0){
            validationStatus["activity_name"] = false;
            activityNameFeedback = "Il nome dell'esercizio è obbligatorio";
        }
        else if(valueLength < 5 || valueLength > 100){
            validationStatus["activity_name"] = false;
            activityNameFeedback = "Il nome dell'esercizio dev'essere compreso tra 5 e 100 caratteri";
        }
        else {
            validationStatus["activity_name"] = true;
            activityNameFeedback = "";
        }

        this.setState({ 
            activity_name_feedback: activityNameFeedback,
            activity_name : input,
            validation_status: validationStatus
        }, () => {
            this.isFormValid();
        });
    }

    validateBusinessName = ( input ) => {

        const valueLength = input.length;
        const validationStatus = this.state.validation_status;
        var businessNameFeedback = "";

        if(valueLength === 0){
            validationStatus["business_name"] = false;
            businessNameFeedback = "La ragione sociale è obbligatoria";
        }
        else if(valueLength < 5 || valueLength > 100){
            validationStatus["business_name"] = false;
            businessNameFeedback = "La ragione sociale dev'essere compresa tra 5 e 100 caratteri";
        }
        else {
            validationStatus["business_name"] = true;
            businessNameFeedback = "";
        }

        this.setState({ 
            business_name_feedback : businessNameFeedback,
            business_name : input,
            validation_status: validationStatus
        }, () => {
            this.isFormValid();
        })
    }
    
    validateVatCode = (input) => {
		// Sanitized input
		const newInput = input.trim();
		//
        const valueLength = newInput.length;
        const validationStatus = this.state.validation_status;
        var vatCodeFeedback = "";

        if(valueLength === 0){
            validationStatus["vat_code"] = false;
            vatCodeFeedback = "La partita IVA è obbligatoria";
        }
        else if(valueLength !== 11){
            validationStatus["vat_code"] = false;
            vatCodeFeedback = "La partita IVA deve contenere 11 caratteri";
        }
        else {
            //Validate vat code with an algorithm
            let isVatCodeValid = checkVatCode(newInput);
            validationStatus["vat_code"] = isVatCodeValid ? true : false;
            vatCodeFeedback = isVatCodeValid ? "" : "La partita IVA non è valida";
        }
		// customerVatCode is going to be updated for passing it on RtActivationFormData activity_vatcode field
		this.props.updateCustomerVatCode(newInput)

        this.setState({ 
            vat_code_feedback   : vatCodeFeedback,
            vat_code            : newInput,
            validation_status   : validationStatus
        }, () => {
            this.isFormValid();
        })
    }

    validateFiscalCode = (input) => {
		// Sanitized input
		const newInput = input.trim();
		//
        const valueLength       = newInput.length;
        const validationStatus  = this.state.validation_status;
        let isFiscalCodeValid   = this.props.errors === "fiscal_code" ? false : true;
        let fiscalCodeFeedback  = "";
		if(valueLength > 0) {
			if(valueLength !== 11 && valueLength !== 16){
				isFiscalCodeValid   = false;
				fiscalCodeFeedback  = "Il codice fiscale deve contenere 11 o 16 caratteri";
			}
			else if(valueLength === 11){
				isFiscalCodeValid   = checkVatCode(newInput);
				fiscalCodeFeedback  = isFiscalCodeValid ? "" : "Il codice fiscale non è valido";
			}
			else if(valueLength === 16){
				isFiscalCodeValid   = checkFiscalCode(newInput);
				fiscalCodeFeedback  = isFiscalCodeValid ? "" : "Il codice fiscale non è valido";
			}
		}
        // let isFiscalCodeValid   = false;
        // let fiscalCodeFeedback  = "Il codice fiscale è obbligatorio";
		/*
        if(valueLength === 0){
            isFiscalCodeValid   = false;
            fiscalCodeFeedback  = "Il codice fiscale è obbligatorio";
        }
        else if(valueLength !== 11 && valueLength !== 16){
            isFiscalCodeValid   = false;
            fiscalCodeFeedback  = "Il codice fiscale deve contenere 11 o 16 caratteri";
        }
        else if(valueLength === 11){
            isFiscalCodeValid   = checkVatCode(newInput);
            fiscalCodeFeedback  = isFiscalCodeValid ? "" : "Il codice fiscale non è valido";
        }
        else if(valueLength === 16){
            isFiscalCodeValid   = checkFiscalCode(newInput);
            fiscalCodeFeedback  = isFiscalCodeValid ? "" : "Il codice fiscale non è valido";
        }
		*/

        validationStatus["fiscal_code"] = isFiscalCodeValid;
            
        this.setState({ 
            fiscal_code_feedback: fiscalCodeFeedback,
            fiscal_code: newInput.toUpperCase(),
            validation_status: validationStatus
        }, () => {
            this.isFormValid();
        })
    }

    validateAddress = (input) => {

        const valueLength       = input.length;
        const validationStatus  = this.state.validation_status;
        var addressFeedback     = "";

        if(valueLength === 0){
            validationStatus["address"] = false;
            addressFeedback = "L'indirizzo è obbligatorio";
        }
        else if(valueLength < 5 || valueLength > 100) {
            validationStatus["address"] = false;
            addressFeedback = "L'indirizzo deve essere compreso tra 5 e 100 caratteri";
        }
        else {
            validationStatus["address"] = true;
            addressFeedback = "";
        }

        this.setState({ 
            address_feedback:addressFeedback,
            address: input,
            validation_status : validationStatus
        }, () => {
            this.isFormValid()
        });
    }

    validateZipCode = (zip_code) => {

        const valueLength = zip_code.length;
        const validationStatus = this.state.validation_status;
        var zipCodeFeedback = "";

        if(valueLength > 5 ) {
            return;
        }

        if(valueLength === 0){
            validationStatus['zip_code'] = false;
            zipCodeFeedback = "Il CAP è obbligatorio";
        }
        else if(valueLength !== 5){
            validationStatus['zip_code'] = false;
            zipCodeFeedback = "Il CAP dev'essere di 5 caratteri";
        }
        else {
            validationStatus['zip_code'] = true;
            zipCodeFeedback = "";
        }

        this.setState({ 
            zip_code_feedback: zipCodeFeedback,
            zip_code: zip_code,
            validation_status : validationStatus
        }, () => {
            this.isFormValid()
        })
    }

    isFormValid = () => {
        let validationStatusList = Object.values(this.state.validation_status);
        
        let validationStatus = validationStatusList.every((fieldValidationStatus) => {
            return fieldValidationStatus ? true : false
        });
        
        this.props.isStepValidated(validationStatus, this.props.tabId);
        if(validationStatus) this.saveData();
    }

    saveData = () => {

        let data = {
            activity_name : this.state.activity_name,
            business_name : this.state.business_name,
            vat_code : this.state.vat_code,
            fiscal_code : this.state.fiscal_code,
            address : this.state.address,
            zip_code : this.state.zip_code
        }

        this.props.saveCustomerData(data);

    }

    render() {
        return (
            <React.Fragment>
                <br/>
                <h5>Dati Anagrafici Esercizio</h5>
                <hr></hr>
                <FormGroup>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="activity_name">Nome Esercizio</Label>
                        <Input type="text" name="activity_name" id="activity_name" placeholder=""  
                            invalid={this.state.validation_status.activity_name ? false : true }
                            onChange={e => this.validateActivityName(e.target.value)} 
                            value={this.state.activity_name}/>
                    </div>
                    <FormFeedback>{this.state.activity_name_feedback}</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <div className={isMobile ? 'flex-row' : ''}>
                        <Label for="business_name">Ragione Sociale</Label>
                        <Input 
                            type="text" name="business_name" id="business_name" placeholder="" 
                            invalid={this.state.validation_status.business_name ? false : true } 
                            onChange={e => this.validateBusinessName(e.target.value)} 
                            value={this.state.business_name}/>
                    </div>
                    <FormFeedback>{this.state.business_name_feedback}</FormFeedback>
                </FormGroup>
                <Row form>
                    <Col md={6}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label for="vat_code">Partita IVA</Label>
                                <Input 
                                    type="text" name="vat_code" id="vat_code" placeholder="" 
                                    invalid={this.state.validation_status.vat_code ? false : true } 
                                    onChange={e => this.validateVatCode(e.target.value)} 
                                    value={this.state.vat_code}/>
                            </div>
                            <FormFeedback>{this.state.vat_code_feedback}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label for="fiscal_code">Codice Fiscale</Label>
                                <Input type="text" name="fiscal_code" id="fiscal_code" placeholder="" 
                                    invalid={this.state.validation_status.fiscal_code  ? false : true } 
                                    onChange={e => this.validateFiscalCode(e.target.value)} 
                                    value={this.state.fiscal_code}/>
                            </div>
                            <FormFeedback>{this.state.fiscal_code_feedback}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={8}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label for="address">Indirizzo</Label>
                                <Input type="text" name="address" id="address" placeholder=""  
                                    invalid={this.state.validation_status.address ? false : true }
                                    onChange={e => this.validateAddress(e.target.value)} 
                                    value={this.state.address}/>
                            </div>
                            <FormFeedback>{this.state.address_feedback}</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={4}>
                        <FormGroup>
                            <div className={isMobile ? 'flex-row' : ''}>
                                <Label for="zip_code">CAP</Label>
                                <Input 
                                    type="number" 
                                    name="zip_code" 
                                    id="zip_code" 
                                    placeholder="" 
                                    invalid={this.state.validation_status.zip_code ? false : true } 
                                    onChange={e => this.validateZipCode(e.target.value)} 
                                    value={this.state.zip_code} />
                            </div>
                            <FormFeedback>{this.state.zip_code_feedback}</FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
}
