import request from '../helpers/request.js';
import localStorage from '../helpers/localStorage.js';
import { getByFiscalNumber } from './devices.js';

const host = process.env.REACT_APP_API_RT_SERVICE_HOST;
const hostAPI = process.env.REACT_APP_API_API_SERVICE_HOST;
const endpoint = process.env.REACT_APP_RT_SERVICES_ENDPOINT;

export const getServices = (idDevice) => {
	const url = `${endpoint}/get-by-id/${idDevice}`;

    return new Promise((resolve, reject) => {
        request(
            host, { 
                method: 'GET', 
                url : url, 
                config : {
                    headers: {'Authorization': `Bearer ${localStorage.getAccessToken()}`},
                    withCredentials: true
                }}
        ).then( 
            response => {
                resolve(response)
            }
        ).catch( err => {
            reject(err);
        })
    });

}


export const addService = async(fiscalNumber, type) => {
	const device = await getByFiscalNumber(fiscalNumber);
	const url = hostAPI + "/four-co-frontend/v1/rt-devices/" + device.data.id + "/save-service-request";
	return new Promise(( resolve, reject) => {

		if(device.status !== 200) {
			return reject({
				data: []
			});
		}

		const body = {
			type: type,
			recurring: true
		};

		request(
			hostAPI, { 
				method: 'POST', 
				url : url,
				body: body, 
				config: { 
					headers: { 
						"Content-Type": "application/json", 
						accept: "*/*", 
						'Authorization': `Bearer ${localStorage.getAccessToken()}`,
					},
					// withCredentials: true
				}
			}
		).then( 
			response => {
				resolve(response)
			}
		).catch( err => {
			reject(err);
		})

	})
}