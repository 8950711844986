import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const Dialog = (props) => {

	const onToggleFunction = () => {
		props.toggle();
	}

	const onCancelFunction = (e) => {
		if(typeof props.onCancel === "function") {
			props.onCancel();
		}
		onToggleFunction();
	}

	const onSuccessFunction = () => {
		if(typeof props.onSuccess === "function") {
			props.onSuccess();
		}
		onToggleFunction();
	}

	return (
        <Modal id="dialog-modal" isOpen={props.isOpen} size="sm" toggle={onToggleFunction}>
            <ModalHeader>
				Attenzione!
            </ModalHeader>
            <ModalBody>
				{props.text}
				<hr/>
				Sei sicuro di voler continuare?
			</ModalBody>
			<ModalFooter>
				<Button color="success" onClick={onSuccessFunction}>Continua</Button>
				<Button color="danger" onClick={onCancelFunction}>
					<FontAwesomeIcon icon={faTimes} />
					{' '}Annulla
				</Button>
			</ModalFooter>
		</Modal>
	);
}

export default Dialog;